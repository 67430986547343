// sort_controller.js
import { Controller } from "stimulus";
import {Sortable} from '@shopify/draggable';

export default class extends Controller {
    connect() {
        console.log('sort controller');
        this.sortable = new Sortable(document.querySelectorAll('div.ul'), {
            draggable: '.item',
        });
    }
}

