import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';
import { Alert, Autosave, ColorPreview, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components";
import RailsNestedForm from '@stimulus-components/rails-nested-form';


const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', {eager: true});

registerControllers(application, controllers);

application.register('nested-form', RailsNestedForm);
application.register('alert', Alert);
application.register('autosave', Autosave);
application.register('color-preview', ColorPreview);
application.register('dropdown', Dropdown);
application.register('modal', Modal);
application.register('popover', Popover);
application.register('slideover', Slideover);
application.register('tabs', Tabs);
application.register('toggle', Toggle);
