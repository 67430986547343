// hide_controller.js
import { Controller } from "stimulus";
import {Sortable} from '@shopify/draggable';

export default class extends Controller {
    connect() {
        const containerSelector = 'div.ul';
        const containers = document.querySelectorAll(containerSelector);
        this.sortable = new Sortable(containers, {
            draggable: '.item',
            mirror: {
                appendTo: containerSelector,
                constrainDimensions: true,
            }
        });
    }


}

