<script>
 let counter = $state(0);

 function handler() {
     counter+=1;
 }

</script>

<h1 class="mb-4 color-red-100">Svelte Header</h1>

<button on:click={handler}>click</button>

<p>{counter}</p>
