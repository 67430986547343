// dialog_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [ "form" ]

    connect() {
        console.log('dialog connected');
    }

    onKeyDown(event) {
        if(event.key === 'Enter') {
            event.preventDefault();
            this.formTarget.requestSubmit();
            //event.target.value = '';
            event.target.disabled=true;
        }


    }


}

