// hello_controller.js
import { Controller } from "stimulus";
import AudioPlayer from "@/js/lib/Player";
import { FetchRequest } from '@rails/request.js';
import debounce from 'lodash/debounce';
import remove  from 'lodash/remove';

class Region {
    constructor({id, wsRegion, start, end, content= ''}) {
        this.id = id;
        this.wsId = wsRegion.id;
        this.region = wsRegion;
        this.start = start;
        this.end = end;
        this.content = content;
    }
    setContent(newContent) {
        this.content = newContent;
    }
    setId(newId) {
        this.id = newId;
    }

    setStart(newStart) {
        this.start = newStart;
    }

    setEnd(newEnd) {
        this.end = newEnd;
    }
}

export default class extends Controller {
    static values = {
        track: String,
        lessonId: String
    }
    static targets = [ "waveform", "speed", "regionContent", "loop" ]

    connect() {
        this.playerInited = false;
        this.regions = [];
        this.activeRegion = null;
        this.player = AudioPlayer({
            trackUrl: this.trackValue,
            onInited: this.onEnablePlayer.bind(this),
            onChangeRegion: this.onSelectRegion.bind(this),
            onRegionUpdate: this.onRegionUpdate.bind(this)
        });

        this.updateDB = debounce(this._updateDB, 2000);
        console.log('player initited');
    }

    onEnablePlayer() {
        this.playerInited = true;

        const regionAdd = this.regionAdd.bind(this);
        gon.regions.forEach(region => regionAdd(region.start, region.end, region.id, region.content));
    }

    onSelectRegion(wsRegion) {
        const foundRegion = this.regions.find( (r)=> r.wsId == wsRegion.id);
        this._setupActiveRegion(foundRegion);
    }

    onRegionUpdate(wsRegion) {
        //console.log({id: region.id, start: region.start, end: region.end, content: region.content});

        const foundRegion = this.regions.find( (r)=> r.wsId == wsRegion.id);

        foundRegion.setStart(wsRegion.start);
        foundRegion.setEnd(wsRegion.end);
        this.updateDB(foundRegion);
        //region.setOptions({content: 'qwe'});
        
    }


    async _updateDB(region) {
        console.log('** UPDATE DB');
        console.log(region);
        console.log('** UPDATE DB');
        const {id, start, end, content} = {id: region.id, start: region.start, end: region.end, content: region.content};

        const request = new FetchRequest('patch', `/my/regions/${id}.json`, { body: JSON.stringify({ start, end, content }) });
        const response = await request.perform();
    }

    _setupActiveRegion(region) {
        if (this.activeRegion != region) {
            this.activeRegion = region;
            this.regionContentTarget.value = this.activeRegion.content;
            this.player.setLoop(true);
            this.loopTarget.checked =true;

        }
    }

    regionAdd(start,end, id = null, content = null) {
        const newWsRegion = this.player.addRegion(start,end);
        const newRegion = new Region({wsRegion: newWsRegion, start, end, id, content});
        this.regions.push(newRegion);

        if (!id) {
            this._createDB(newRegion);
        }
        return newRegion;
    }

    async _createDB(region) {
        const dialogId = this.lessonIdValue;
        const {start, end} = {start: region.start, end: region.end};

        const request = new FetchRequest('post', `/my/lessons/${dialogId}/regions.json`, { body: JSON.stringify({region: {start, end} }) });
        const response = await request.perform();
        const body = await response.json;
        region.setId(body.id);
    }

    play() {
        if (this.playerInited) {
            this.player.playPause();
        }
    }

    changeRegionContent(e) {
        if(this.activeRegion) {
            const value = e.target.value;
            this.activeRegion.setContent(value);
            this.updateDB(this.activeRegion);
        }
    }

    changeZoom(e) {
        this.player.zoom(e.target.value);
    }
    changeSpeed(e) {
        const newSpeed = e.target.value;
        this.player.setPlaybackRate(newSpeed);
        this.speedTarget.innerHTML = `(${newSpeed}x)`;
    }

    addRegion() {
        const startPosition = this.player.currentTime;
        const newRegion = this.regionAdd(startPosition, startPosition+3);
        this._setupActiveRegion(newRegion);
    }

    smallRight() {
        this.player.activeRegion.setOptions({end: this.activeRegion.end + 0.1});
        // console.log(this.player.regions);
        // console.log(this.player.activeRegion);
    }

    bigRight() {
        // console.log('***')
        // console.log(this.player.activeRegion.end);
        this.player.activeRegion.setOptions({end: 10});
        // console.log(this.player.regions);
        // console.log(this.player.activeRegion);
    }

    deleteRegion() {
        let region = this.activeRegion;
        this.activeRegion = null;
        region.region.remove();
        remove(this.regions, r => r.id == region.id);
        this._deleteDB(region);
    }

    changeLoop(e) {
        this.player.setLoop(e.target.checked);
    }


    async _deleteDB(region) {
        const id = region.id;
        const request = new FetchRequest('delete', `/my/regions/${id}.json`);
        const response = await request.perform();
    }



}
