//import "@hotwired/turbo-rails";
import {Turbo } from '@hotwired/turbo-rails';
import "@mariohamann/activity-graph";
import {Idiomorph} from 'idiomorph/dist/idiomorph.esm.js';

import "@controllers";
import "@/components";
import "@/js/Alpine";
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

//TurboMorph.initialize(Turbo.StreamActions);

Turbo.StreamActions.console_log = function() {
    const message = this.getAttribute("message");
    console.log(message);
};


Turbo.StreamActions.xxx = function() {
    console.log('qqqq');
    this.targetElements.forEach((target) => {
        console.log('qqqq');
        Idiomorph.morph(target, this.templateContent);
    });
};


console.log('Vite ⚡️ Rails');
console.log(Turbo.StreamActions);

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>


// Example: Load Rails libraries in Vite.
//
//Turbo.start();
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
