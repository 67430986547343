// hello_controller.js
import { Controller } from "stimulus";
import {init as midiInit} from "@/js/lib/MidiLesson";

export default class extends Controller {
    static targets = [ "toggle"]
    connect() {
        this.isOpened = true;
    }


    toggle() {
        if (this.isOpened) {
            hide();
            
        } else {
            show();
        }
    }

    hide() {
        this.isOpened = false;
    }

    show() {
        this.isOpened = true;
    }
}

