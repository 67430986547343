
import Page from './Page.svelte';

function init() {
    const app = Page(document.getElementById('app'), {});
    return app;
}


export {init}
