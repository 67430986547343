
import { initialize } from "@ionic/core/components";
import { defineCustomElement as Accordion } from "@ionic/core/components/ion-accordion.js";
import { defineCustomElement as AccordionGroup } from "@ionic/core/components/ion-accordion-group.js";
import { defineCustomElement as Button } from "@ionic/core/components/ion-button.js";

import '@lottiefiles/dotlottie-wc';
import './navbar.js';

initialize();
Accordion();
AccordionGroup();
Button();

console.log('Web Components ⚡️ Rails')
