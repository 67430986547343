// sort_controller.js
import { Controller } from "stimulus";
import {init} from "@/pages/sort/index.js";

export default class extends Controller {
    connect() {
        window.page = init();
    }
}

