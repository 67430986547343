import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
import ZoomPlugin from 'wavesurfer.js/dist/plugins/zoom.esm.js';
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js';

const random = (min, max) => Math.random() * (max - min) + min;
const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`;

export default function({trackUrl, onInited, onChangeRegion, onRegionUpdate}) {

    const ws = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#4F4A85',
        mediaControls: true,
        progressColor: '#383351',
        barWidth: 2,
        barGap:1,
        normalize: true,
        barRadius:2,
        url: trackUrl,
    });
    const wsRegions = ws.registerPlugin(RegionsPlugin.create());
    ws.registerPlugin(
        ZoomPlugin.create({
            scale: 0.5, // the amount of zoom per wheel step, e.g. 0.5 means a 50% magnification per scroll
            maxZoom: 600, // Optionally, specify the maximum pixels-per-second factor while zooming
        }),
    );
    ws.registerPlugin(Hover.create({
        lineColor: '#ff0000',
        lineWidth: 1,
        labelBackground: '#555',
        labelColor: '#fff',
        labelSize: '11px',
    }));
    const topTimeline = TimelinePlugin.create({
        height: 15,
        timeInterval: 5,
        primaryLabelInterval: 10,
        style: {
            fontSize: '10px',
            color: '#2D5B88',
        },
    });
    ws.registerPlugin(topTimeline);

    // <STATE>
    let activeRegion= null;
    const regions =  [];
    let loop = true;
    // </STATE>

    const addRegion = (start, end) => {
        const newRegion =wsRegions.addRegion({
            start,
            end,
            color: randomColor(),
            resize: true,
        });
        regions.push(newRegion);
        return newRegion;
    };

    ws.on('decode', () => {
        if (onInited) {
            onInited();
        }
    });

    wsRegions.on('region-in', (region) => {
        activeRegion = region;
    });

    wsRegions.on('region-out', (region) => {
        // console.log('region-out');
        if (activeRegion === region) {
            if (loop) {
                region.play();
            } else {
                activeRegion = null;
            }
        }
    });

    wsRegions.on('region-clicked', (region, e) => {
        // console.log('region-clicked');
        e.stopPropagation();// prevent triggering a click on the waveform
        activeRegion = region;
        if (onChangeRegion) {
            onChangeRegion(activeRegion);
        }
        region.play();
    });

    ws.on('interaction', () => {
        activeRegion = null;
    });

    wsRegions.on('region-updated', (region) => {
        if (onRegionUpdate) {
            onRegionUpdate(region);
        }
    });

    const getActiveRegion = () => {
        return activeRegion;
    };

    const playPause = () => {
        ws.playPause();
    };

    const shiftRightEnd = () => {

    };
    const shiftLefEnd = () => {
    };

    const getCurrentTime = () => {
        return ws.getCurrentTime();
    };

    const zoom = (value) => {
        ws.zoom(value);
    };
    const resetZoom =() => {
        ws.zoom(1);
    };
    const setPlaybackRate = (speed) => {
        ws.setPlaybackRate(speed, true);
    };

    const setLoop = (newBool) => {
        loop = newBool;
    };

    return {
        playPause,
        addRegion,
        zoom,
        resetZoom,
        setPlaybackRate,
        setLoop,
        get loop() {
            return loop;
        },
        get currentTime() {
            return getCurrentTime();
        },
        get activeRegion() {
            return activeRegion;
        },
        get regions() {
            return regions;
        }
    };
};

